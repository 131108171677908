<template>
  <main class="h-100 d-flex flex-column">
      <nav class="navbar navbar-dark fixed-top bg-dark">
        <div class="container">
            <router-link class="navbar-brand" :to="{ name: 'dashboard'}">
              <img src="./assets/Lakeside_Logo_1c.svg" alt="" width="129" height="36">
            </router-link >
            <span v-if="config.debug || admin" class="text-danger d-none d-md-inline" style="font-weight: bold;">{{ config.url }}</span>
            <button v-if="$auth.check()" @click="menu = !menu" class="navbar-toggler collapsed" style="color: white; z-index: 99999;">
              <i class="fa-solid fa-bars fa-fw" v-if="!menu"></i>
              <i class="fa-solid fa-xmark fa-fw" v-else></i>
            </button>
        </div>
      </nav>
    <div class="bg-light py-2" v-if="$auth.check() && (config.debug || admin)">
        <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-9 col-lg-6 col-xl-6">
                <div class="input-group" v-if="admin">
                  <button class="btn btn-secondary dropdown-toggle" type="button" style="min-width: auto;" data-bs-toggle="dropdown" aria-expanded="false">Historie</button>
                  <ul class="dropdown-menu ">
                    <li v-if="!history.length"><p class="px-3 text-black-50">Keine Einträge vorhanden</p></li>
                    <li><a class="dropdown-item" href="#" v-for="user in history" :key="user.id" @click="login(user.id)">
                      {{ user.id }}
                      <span style="display:block; font-size: 0.75rem;">{{user.agilea.lastName}}, {{user.agilea.firstName}}</span>
                    </a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="#" @click="history = []">Historie leeren</a></li>
                  </ul>

                  <input class="form-control" type="search" placeholder="Mitgliedsnummer" v-model="memberId" aria-label="Search">
                  <button class="btn btn-primary" @click="login(memberId)" type="submit" style="min-width: auto;">Login</button>
                </div>

              </div>
              <div class="col-md-3">
                <button class="btn btn-secondary mt-3 mt-md-0" style="width: 100%" @click="showRawData=true">Agilea Raw-Daten</button>
              </div>
            </div>
        </div>
    </div>
    <router-view class="flex-grow-1 pb-5"></router-view>

      <aside id="menu" :class="{active: showMenu()}">
          <ul>
            <li><router-link :to="{name: 'dashboard'}">Meine Daten</router-link></li>
            <li><router-link :to="{name: 'studio'}">Mein Studio</router-link></li>
            <li><router-link :to="{name: 'contract'}">Mein Vertrag</router-link></li>
            <li><router-link :to="{name: 'kk'}">KK-Rückerstattung</router-link></li>
            <li><router-link :to="{name: 'partner'}">Partner</router-link></li>
            <li><router-link :to="{name: 'faq'}">FAQs</router-link></li>
            <li><router-link :to="{name: 'contact'}">Kontakt</router-link></li>
            <li><a href="#" @click="logout()">Logout</a></li>
          </ul>
      </aside>

    <CModal :visible="showRawData" alignment="center" @close="showRawData=false" size="xl">
      <CModalHeader>
        <CModalTitle>Agilea Raw Data</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink
                href="javascript:void(0);"
                :active="tabPaneActiveKey === 1"
                @click="() => {tabPaneActiveKey = 1}"
            >
              User
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
                href="javascript:void(0);"
                :active="tabPaneActiveKey === 2"
                @click="() => {tabPaneActiveKey = 2}"
            >
              Contract
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
            <vue-json-pretty :data="$auth.user()"></vue-json-pretty>
          </CTabPane>
          <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 2">
            <vue-json-pretty :data="$store.state.contract.contract" ></vue-json-pretty>
          </CTabPane>
        </CTabContent>

      </CModalBody>
      <CModalFooter class="text-end">
            <button class="btn btn-secondary" @click="showRawData=false">Schließen</button>
      </CModalFooter>
    </CModal>

    <CModal :visible="showPaymentFeedback" alignment="center" @close="showPaymentFeedback=false">
      <CModalHeader>
        <CModalTitle>Zahlungsmittelaktualisierung</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p v-if="paymentFeedback=='accept'">
          <i class="fa-solid fa-thumbs-up fa-2xl text-success" style="margin-right: 3rem;"></i> Du hast dein Zahlungsmittel erfolgreich aktualisiert.
        </p>
        <p v-else-if="paymentFeedback=='decline'">
          <i class="fa-solid fa-xmark fa-2xl text-danger"></i> Deine Zahlungsmittelaktualisierung wurde nicht akzeptiert. Versuche bitte eine andere Zahlungsmethode.
        </p>
        <p v-else-if="paymentFeedback=='exception'">
          <i class="fa-solid fa-exclamation fa-2xl text-danger"></i> Leider ist ein unbekannter Fehler aufgetreten. Bitte versuche es zu einem späteren Zeitpunkt erneut.
        </p>
        <p v-else-if="paymentFeedback=='cancel'">
          Vorgang erfolgreich abgebrochen.
        </p>
      </CModalBody>
      <CModalFooter class="text-end">
        <button class="btn btn-primary" @click="showPaymentFeedback=false">Schließen</button>
      </CModalFooter>
    </CModal>


  </main>
</template>

<script>
import {
  CModal, CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
    CNav, CNavItem, CNavLink, CTabContent, CTabPane
} from "@coreui/bootstrap-vue";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import axios from "axios";

export default {
  name: 'App',
  components: {
    CModalTitle,
    CModalHeader, CModal, CModalFooter, CModalBody,VueJsonPretty,
    CNav, CNavItem, CNavLink, CTabContent, CTabPane
  },
  watch: {
    '$route' (to, from) {
      this.$gtag.pageview(this.$route);
      this.menu = false;
    }
  },
  computed:{
      contract() {
        return this.$store.state.contract.contract;
      },
      admin(){
        return this.$store.state.admin;
      },
      adminToken(){
        return this.$store.state.adminToken;
      }
  },
  data(){
    return {
        config:{
          url: "https://tbc.bitdivision.de/api",
          debug: false,
          staging: false,
        },
        history: [],
        memberId: null,
        menu: false,
        showRawData: false,
        showPaymentFeedback: false,
        paymentFeedback: null,
        tabPaneActiveKey: 1,
    };
  },
  mounted(){
    if(window.myLogin && window.myLogin.config){
      this.config = this.extend(this.config, window.myLogin.config);
    }

    axios.defaults.baseURL = this.config.url;
    axios.defaults.params = {'lang': "de"};


    this.concardisFeedback();

    let timeout = setInterval(() => {
      if(this.$auth.ready()){
        clearInterval(timeout);
      }

      if(this.$auth.check()){
        this.$contract.fetch();
      }
    }, 300);
  },
  methods:{
    extend(obj1, obj2) {
      let keys = Object.keys(obj2);
      for (let i = 0; i < keys.length; i += 1) {
        let val = obj2[keys[i]];
        obj1[keys[i]] = ['string', 'number', 'array', 'boolean'].indexOf(typeof val) === -1 ? this.extend(obj1[keys[i]] || {}, val) : val;
      }
      return obj1;
    },
    concardisFeedback(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if(urlParams.has('bftype')){
          this.paymentFeedback = urlParams.get('bftype');
          this.showPaymentFeedback = true;
      }
    },
    isGroupFitnessAvailable(){
      if(this.contract == false) return false;

      return (this.contract.isGroupFitnessAvailable || this.contract.isAllStudios);
    },
    isGroupFitnessAllowed(){
      if(this.$store.state.contract.contract == false) return false;

      return this.$store.state.contract.contract.isGroupFitnessAvailable;
    },
    login(memberId){
        if(!this.admin) return false;

        this.axios.post("auth/changeUser", {memberId: memberId, adminToken: this.adminToken}).then(res => {
            let oldUser = this.$auth.user();

            if(res.data.status == "success"){
              this.$auth.token(null, res.data['_token']);

              if(res.data.admin && res.data.admin.admin === true){
                this.$store.commit('setAdminStatus', res.data.admin.adminToken);
              }

              this.$auth.fetch().then(() => {
                this.$toast.success('Login erfolgreich! Sie sind nun als  <strong>' + this.$auth.user().agilea.firstName + " " + this.$auth.user().agilea.lastName + "</strong> eingeloggt.");
                this.$contract.fetch();
              });

              this.history.push(oldUser);
              this.memberId = '';

              this.axios.get('myLogin/contracts').then(res => {
                this.$store.commit('contract/setContract', res.data.contract);
              });
            }
            else{
              this.$toast.error('Login ist fehlgeschlagen.');
            }

        }).catch(error => {
          this.$toast.error('Ein unbekannter Fehler ist aufgetreten!<br><b>Error: </b>' + error.response.status.toString() );
        });
    },
    logout(){
      this.$store.commit("unsetAdminStatus");
      this.$auth.logout();
    },
    showMenu(){
      return this.menu && this.$auth.check();
    }
  }
}
</script>

<style>

</style>
