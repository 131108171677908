<template>
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col col-md-6 offset-md-3">
                  <h1 style="line-height: 1.1em;">Willkommen bei myLogin</h1>
                  <p class="mb-5">Bitte logge dich in deinem Konto ein.</p>

                  <CFormFloating class="mb-3">
                    <CFormInput type="email" id="email" placeholder="example@example.ch" v-model="formData.email"/>
                    <CFormLabel for="email"><i class="fa-solid fa-envelope me-3  ms-1"></i> E-Mail Adresse</CFormLabel>
                  </CFormFloating>
                  <CFormFloating>
                    <CFormInput type="password" id="password" placeholder="*******" v-model="formData.password"/>
                    <CFormLabel for="password"><i class="fa-solid fa-key me-3  ms-1"></i> Passwort</CFormLabel>
                  </CFormFloating>
                  <div class="mb-3">
                    <router-link :to="{ name: 'forgetPassword'}">Passwort vergessen?</router-link>
                  </div>
                  <div class="d-grid gap-2">
                    <button class="btn btn-primary btn-lg " @click="login">Login</button>
                  </div>
                  <div>Du hast noch keinen Account? <router-link :to="{ name: 'register'}">Jetzt registrieren</router-link></div>
                </div>

            </div>
        </div>

</template>


<script>

import { CFormFloating, CFormInput,  CFormLabel} from '@coreui/bootstrap-vue';

export default {
  name: "LoginForm",
  components: {
    CFormFloating,CFormInput,  CFormLabel
  },
  data(){
    return {
        formData: {
          email: "",
          password: "",
          device: 'browser',
        },
        has_error: false,
      };
  },
  mounted(){
  },
  methods: {
    login(){
      let redirect = this.$auth.redirect();
      let app = this;

      this.$auth.login( {
        data: {
          email: this.formData.email,
          password: this.formData.password
        },
        rememberMe: false,
        fetchUser: false,
      }).then(response => {
        app.has_error = false;

        if(response.data.admin && response.data.admin.admin === true){
          this.$store.commit('setAdminStatus', response.data.admin.adminToken);
        }

        this.$gtag.event('login', { 'event_category': 'Login', method: 'EMail-Address' });

        this.$auth.fetch();
        this.$contract.fetch();
        this.$router.push({name: 'dashboard'});
      }, (res) => {
        this.$toast.error('E-Mail Adresse oder Passwort sind nicht korrekt. Bitte versuche es erneut!');
      });
    }
  }
}
</script>

<style scoped>

</style>