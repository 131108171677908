<template>
  <div id="dataUpdate">
    <div class="row">
      <div class="col inner py-3">

    <h3 class="text-center mb-3">Daten aktualisieren</h3>
    <CFormFloating class="mb-3">
      <CFormInput type="email" :class="{'is-invalid': hasError('email')}" id="email" placeholder="*******" v-model="formData.email"/>
      <CFormLabel for="email">Deine E-Mail Adresse</CFormLabel>
      <div v-if="hasError('email')" class="error">
        {{errors.email[0]}}
      </div>
    </CFormFloating>
    <hr>
    <CFormFloating class="mb-3">
      <CFormInput type="text" :class="{'is-invalid': hasError('street')}" id="street" placeholder="*******" v-model="formData.street"/>
      <CFormLabel for="street">Dein Strasse</CFormLabel>
      <div v-if="hasError('street')" class="error">
        {{errors.street[0]}}
      </div>
    </CFormFloating>
    <CFormFloating class="mb-3">
      <CFormInput type="text" :class="{'is-invalid': hasError('zip')}" id="zip" placeholder="*******" v-model="formData.zip"/>
      <CFormLabel for="zip">Deine Postleitzahl</CFormLabel>
      <div v-if="hasError('zip')" class="error">
        {{errors.zip[0]}}
      </div>
    </CFormFloating>
    <CFormFloating class="mb-3">
      <CFormInput type="text" :class="{'is-invalid': hasError('city')}" id="city" placeholder="*******" v-model="formData.city"/>
      <CFormLabel for="city">Deine Stadt</CFormLabel>
      <div v-if="hasError('city')" class="error">
        {{errors.city[0]}}
      </div>
    </CFormFloating>

    <div class="text-center">
      <button class="btn btn-primary" :disabled="!isValid()" @click="submit()"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Daten aktualisieren</button>
    </div>
        <div class="menu text-center mt-3">
          <span @click="$emit('close')">Abbrechen</span>
        </div>
      </div></div>
  </div>
</template>

<script>
import {CFormFloating, CFormInput, CFormLabel} from "@coreui/bootstrap-vue";

export default {
  name: "HomeMyDataUpdate",
  components: {
    CFormFloating,CFormInput,  CFormLabel
  },
  data(){
    return {
      formData:{
        email: '',
        street: '',
        zip: '',
        city: '',
      },
      errors: [],
      loading: false,
    }
  },
  mounted() {
    this.formData.email = this.$auth.user().agilea.email;
    this.formData.street = this.$auth.user().agilea.street;
    this.formData.zip = this.$auth.user().agilea.zip;
    this.formData.city = this.$auth.user().agilea.city;

    this.$gtag.pageview('/Daten-anpassen');
  },
  methods:{
    hasError($field){
      if(this.errors && this.errors[$field]) return true;

      return false;
    },
    isValid(){
      if(this.loading) return false;
      if(!this.formData.email) return false;
      if(!this.formData.street) return false;
      if(!this.formData.zip) return false;
      if(!this.formData.city) return false;

      if(
          this.formData.email == this.$auth.user().agilea.email &&
          this.formData.street == this.$auth.user().agilea.street &&
          this.formData.zip == this.$auth.user().agilea.zip &&
          this.formData.city == this.$auth.user().agilea.city
      ) return false;

      return true;
    },
    submit(){
      this.loading = true;
      this.axios.post("myLogin/updateData", this.formData).then(res => {
        if(res.data.success == true){

          this.$auth.fetch().then(() => {
            this.$toast.success('Deine Änderungen wurden erfolgreich aktualisiert.');
          });
          this.loading = false;
          this.$emit('close');
        }
        else{
          this.$toast.error('Leider ist ein unbekannter Fehler aufgetreten. Versuche es bitte später noch einmal.');
          this.loading = false;
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

        if(error.response.status != 422){
          this.$toast.error('Ein unbekannter Fehler ist aufgetreten!<br><b>Error: </b>' + error.response.status.toString() );
        }
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>