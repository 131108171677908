import { createStore } from 'vuex';
import contract from './contract';

const store = createStore({
    modules: {
        contract: contract,
    },
    state(){
        return {
            admin: false,
            adminToken: null,
        };
    },
    mutations: {
        setAdminStatus(state, token){
            state.admin = true;
            state.adminToken = token;
        },
        unsetAdminStatus(state, token){
            state.admin = false;
            state.adminToken = null;
        }
    },
})

export default (app) => {
    app.use(store);
}