<template>
  <div class="password">
    <div class="row">
      <div class="col inner py-3">
    <h3 class="text-center mb-3">Passwort ändern</h3>
    <CFormFloating class="mb-3">
      <CFormInput type="password" id="pwdOld" :class="{'is-invalid': hasError('pwdOld')}" placeholder="*******" v-model="formData.pwdOld"/>
      <CFormLabel for="pwdOld">Dein aktuelles Passwort</CFormLabel>
      <div v-if="hasError('pwdOld')" class="error">
        {{errors.pwdOld[0]}}
      </div>
    </CFormFloating>
    <hr>
    <CFormFloating class="mb-3">
      <CFormInput type="password" id="pwdNew" :class="{'is-invalid': hasError('pwdNew')}" placeholder="*******" v-model="formData.pwdNew"/>
      <CFormLabel for="pwdNew">Dein neues Passwort</CFormLabel>
      <div v-if="hasError('pwdNew')" class="error">
        {{errors.pwdNew[0]}}
      </div>
    </CFormFloating>
    <CFormFloating class="mb-3">
      <CFormInput type="password" id="pwdNew2"  :class="{'is-invalid': hasError('pwdNew2')}" placeholder="*******" v-model="formData.pwdNew2"/>
      <CFormLabel for="pwdNew2">Bitte wiederhole dein Passwort</CFormLabel>
      <div v-if="hasError('pwdNew2')" class="error">
        {{errors.pwdNew2[0]}}
      </div>
    </CFormFloating>

    <div class="text-center">
      <button class="btn btn-primary" @click="submit()" :disabled="!isValid()"><span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Passwort ändern</button>
    </div>
        <div class="menu text-center mt-3">
          <span @click="$emit('close')">Abbrechen</span>
        </div>
      </div></div>
  </div>
</template>

<script>
import { CFormFloating, CFormInput,  CFormLabel} from '@coreui/bootstrap-vue';

export default {
  name: "HomeMyDataPassword",
  components: {
    CFormFloating,CFormInput,  CFormLabel
  },
  data(){
    return {
        formData:{
          pwdOld: '',
          pwdNew: '',
          pwdNew2: ''
        },
        errors: [],
        loading: false,
    }
  },
  mounted() {
    this.$gtag.pageview('/Passwort-aendern');
  },
  methods:{
    hasError($field){
      if(this.errors && this.errors[$field]) return true;

      return false;
    },
    isValid(){
        if(this.loading) return false;
        if(!this.formData.pwdOld) return false;
        if(!this.formData.pwdNew) return false;
        if(!this.formData.pwdNew2) return false;

        return true;
    },
    submit(){
      this.loading = true;
      this.axios.post("myLogin/updatePassword", this.formData).then(res => {
        if(res.data.success == true){
          this.$toast.success('Dein Passwort wurde erfolgreich aktualisiert.');
          this.$emit('close');
          this.loading = false;
        }
        else{
          let msg = 'Leider ist ein unbekannter Fehler aufgetreten. Versuche es bitte später noch einmal.';
          if(res.data.message){
              msg = res.data.message;
          }
          this.$toast.error(msg);
          this.loading = false;
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

        if(error.response.status != 422){
          this.$toast.error('Ein unbekannter Fehler ist aufgetreten!<br><b>Error: </b>' + error.response.status.toString() );
        }
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>