export default {
    install: (app, store) => {
        let fetch = () => {
            app.axios.get('myLogin/contracts').then(res => {
                app.config.globalProperties.$store.commit('contract/setContract', res.data.contract);
            });
        }

        app.config.globalProperties.$contract = {
            fetch: fetch,
        };
    }
}

/*function plugin(Vue, options) {
    if (plugin.installed) {
        return;
    }

    options = options || {};

    Vue.contract = {
        fetch: () => {
            alert('fetch');
            /*this.axios.get('myLogin/contracts').then(res => {
                this.$store.commit('contract/setContract', res.data.contract);
            });
        }
    };


    /*Object.defineProperties(Vue, {
        $contract: {
            fetch(){
                return Vue.contract.fetch();
            },
            get() {
                return Vue.contract;
            }
        }
    });
}

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(plugin);
}

export default plugin */
