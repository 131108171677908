import { createApp } from 'vue'
import router from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import auth from "@/auth";
import VueToast from 'vue-toast-notification';
import store from "@/store/store";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import VueGtag from "vue-gtag";

import contractHandler from "@/contractHandler";





const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(auth)
    .use(VueToast,{
        position: 'bottom',
        duration: 6000,
    })
    .use(contractHandler, store)
    .use(VueGtag, {
        config: {
            //id: "UA-54359917-2",
            params: {
                anonymize_ip: true
            }
        },
        router
    });


    app.config.globalProperties.$filters = {
        formatCurrency(value){
            return new Intl.NumberFormat('de-CH', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2  }).format(parseFloat(value));
        }
    }

    app.mount('#app')

