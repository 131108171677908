<template>
  <div id="myData" class="widget" :class="{active: isActive()}">
    <div v-if="!modus">
      <ul class="dataListing">
        <li>
          <div class="field">Name, Vorname</div>
          <div class="value">{{$auth.user().agilea.lastName}}, {{$auth.user().agilea.firstName}}</div>
        </li>
        <li>
          <div class="field">Mitglieds-Nr.</div>
          <div class="value">{{$auth.user().agilea.id}}</div>
        </li>
        <li>
          <div class="field">Geburtsdatum</div>
          <div class="value">{{birthday}}</div>
        </li>
        <li>
          <div class="field">E-Mail</div>
          <div class="value">{{$auth.user().agilea.email}}</div>
        </li>
        <li>
          <div class="field">Strasse, PLZ, Ort</div>
          <div class="value">{{$auth.user().agilea.street}}, {{$auth.user().agilea.zip}}, {{$auth.user().agilea.city}}</div>
        </li>
        <li>
          <div class="field">Passwort</div>
          <div class="value">****************</div>
        </li>
      </ul>
    </div>
    <home-my-data-password v-if="modus=='password'" @close="setModus(false)"></home-my-data-password>
    <home-my-data-update v-if="modus=='update'" @close="setModus(false)"></home-my-data-update>

    <div class="menu mt-3" v-if="!modus">
      <span @click="setModus('update')">Adresse ändern</span><span @click="setModus('password')">Passwort ändern</span>
    </div>
  </div>
</template>

<script>
import 'moment/locale/de';
import moment from "moment";

import HomeMyDataUpdate from "@/components/HomeMyDataUpdate";
import HomeMyDataPassword from "@/components/HomeMyDataPassword";

export default {
  name: "HomeMyData.vue",
  components: {
    HomeMyDataPassword, HomeMyDataUpdate
  },
  data(){
    return {
        modus: false,
    }
  },
  computed:{
    birthday(){
      if(!this.$auth) { return null; }

      return moment(this.$auth.user().agilea.birthday).format('DD.MM.YYYY');
    }
  },
  methods:{
    setModus(modus){
      this.modus = modus;
    },
    isActive(){
      return this.modus != false;
    }
  }
}
</script>

<style scoped>

</style>