export default  {
    namespaced: true,
    state () {
        return {
            contract: false,
        }
    },
    mutations: {
        setContract(state, contract){
            state.contract = contract;
        }
    },
    actions: {

    }
};